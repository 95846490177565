<template>
  <div class="sa-list-wrap" :style="{ backgroundColor: backgroundColor }">
    <div class="sa-list-wrap__empty" v-if="!getShowBool('select') && !getShowBool('selectList') && useSelectAuth">
      暂无数据权限，请联系管理员添加
    </div>
    <div class="sa-list-wrap__empty" v-else-if="isEmptyProject">暂无项目，请联系管理员添加项目</div>
    <div class="sa-list-wrap__content" v-else v-loading="loading" element-loading-text="加载中...">
      <div class="sa-list-wrap__main">
        <slot />
      </div>
      <div class="sa-list-wrap__empty" v-if="empty && !loading">
        <slot name="empty" v-if="$slots.empty" />
        <span v-else>
          暂无数据<span v-if="hasCreateAuth && createLabel"
            >，立即<a @click="handleCreate">{{ createLabel }}</a></span
          >
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, computed } from 'vue';
import { userProjectList } from '@/layout/messageCenter/user-info';
import _ from 'lodash';
import { getShowBool } from '@/utils/permission-show-module';
export default defineComponent({
  name: 'ListWrap',
  props: {
    // 是否在项目下
    inProject: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: true,
    },
    backgroundColor: {
      type: String,
      default: '#fff',
    },
    empty: {
      type: Boolean,
      default: false,
    },
    hasCreateAuth: {
      type: Boolean,
      default: true,
    },
    handleCreate: {
      type: Function,
      default: _.noop,
    },
    createLabel: {
      type: String,
      default: '新建',
    },
    // 有些情况下，列表确实不需要权限，加个开关
    useSelectAuth: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const isEmptyProject = computed(() => props.inProject && userProjectList?.length === 0);
    return {
      userProjectList,
      isEmptyProject,
      getShowBool,
    };
  },
});
</script>
<style lang="scss">
.sa-list-wrap {
  width: 100%;
  &__empty,
  &__content {
    min-height: 200px;
    overflow: hidden;
  }
  &__empty {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
  }
}
.sa-list-wrap .el-table__empty-block {
  display: none;
}
.sa-list-wrap .el-loading-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  .circular {
    width: 32px;
    height: 32px;
    margin-right: 10px;
  }
}
</style>
