
import { defineComponent, ref } from 'vue';
import CustomColumn from './CustomColumn.vue';
export default defineComponent({
  name: 'DataList',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    showPagination: {
      type: Boolean,
      default: true,
    },
    page: {
      type: Number,
      default: 1,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    total: {
      type: Number,
      default: 2000,
    },
    showSeting: {
      type: Boolean,
      default: true,
    },
    tableColumns: {
      type: Array,
      default: () => [],
    },
  },
  components: { CustomColumn },
  emits: ['pageChange', 'setColumns'],
  setup(props, { emit }) {
    const handlePageSizeChange = (size: number) => {
      emit('pageChange', {
        key: 'pageSize',
        value: size,
      });
    };
    const handlePageChange = (page: number) => {
      emit('pageChange', {
        key: 'page',
        value: page,
      });
    };
    const customColumns = (colnums: any) => {
      emit('setColumns', colnums);
    };
    const customColumn = ref(null);
    const openDrawer = () => {
      (customColumn.value as any).openDrawer();
    };
    return {
      customColumn,
      openDrawer,
      handlePageSizeChange,
      handlePageChange,
      customColumns,
    };
  },
});
