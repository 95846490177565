
import { defineComponent, ref, onMounted } from 'vue';
export default defineComponent({
  name: 'Tooltip',
  props: {
    visibleArrow: {
      type: Boolean,
      default: false,
    },
    placement: {
      type: String,
      default: 'top',
    },
    content: {
      type: String,
      default: '',
    },
  },
  setup() {
    const placeholder: any = ref(null);
    const showTooltip = ref(false);
    onMounted(() => {
      const $el = placeholder.value;
      const $parent = $el.parentNode;
      if ($el.offsetWidth > $parent.offsetWidth) {
        showTooltip.value = true;
      }
    });

    return {
      placeholder,
      showTooltip,
    };
  },
});
