
import { defineComponent, watch } from 'vue';
import { getUser } from '@/shared/userinfo';
import { routerLoading } from '@/layout/messageCenter/routerRef';
import { useRouter, useRoute } from 'vue-router';
import { ElConfigProvider } from 'element-plus';
import zhCn from 'element-plus/lib/locale/lang/zh-cn';
import GlobalIframe from '@/components/global-iframe/Index.vue';
import { resetPremissionRouter } from '@/router';

export default defineComponent({
  components: {
    [ElConfigProvider.name]: ElConfigProvider,
    GlobalIframe,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const openPage = 'service-resource-center';
    if (!window.location.href.includes(openPage)) {
      getUser();
    } else {
      resetPremissionRouter(() => {
        setTimeout(() => {
          routerLoading.value = false;
        }, 200);
      });
      // routerLoading.value = false;
    }
    watch(routerLoading, (nn) => {
      let repath = window.location.search;
      if (repath.includes('/router-loading?redirect=')) {
        repath = repath.slice(25);
      } else if (repath.includes('?redirect=')) {
        repath = repath.slice(10);
      } else {
        repath = window.location.pathname + repath;
      }
      if (!nn) {
        router.push(route.redirectedFrom?.fullPath || repath);
      }
    });

    return {
      locale: zhCn,
    };
  },
});
