
export default {
  name: 'PackagedPagination',
  emits: ['size-change', 'current-change'],
  props: {
    page: {
      type: Number,
      default: 1,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    total: {
      type: Number,
      default: 0,
    },
    pageSizes: {
      type: Array,
      default: () => [10, 20, 50, 100],
    },
    layout: {
      type: String,
      default: 'sizes, prev, pager, next, jumper',
    },
  },
};
