<template>
  <div class="global-iframe" v-show="showIframe">
    <iframe :src="src" frameborder="0" ref="globalIframeRef"></iframe>
  </div>
</template>

<script>
import { defineComponent, ref, watch, onBeforeUpdate } from 'vue';
import { useRoute } from 'vue-router';
import { pathMap, taskList, whiteListPaths } from '@/components/global-iframe/dataCenter';
import { userCurrentProject } from '@/layout/messageCenter/user-info';

export default defineComponent({
  name: 'GlobalIframe',
  setup() {
    const src = ref(`/freedo?projectId=${localStorage.getItem('projectId') || 0}`);
    watch(userCurrentProject, () => {
      if (userCurrentProject.value.id) {
        src.value = process.env.NODE_ENV === 'development' ? `` : `/freedo?projectId=${userCurrentProject.value.id}`;
      }
    });
    const globalIframeRef = ref(null);
    const route = useRoute();
    const showIframe = ref(false);

    const intoFreedoPath = () => {
      if (whiteListPaths.includes(route.path)) {
        if (
          globalIframeRef.value &&
          globalIframeRef.value.contentWindow &&
          globalIframeRef.value.contentWindow.setPath
        ) {
          globalIframeRef.value.contentWindow.setPath(pathMap[route.path].path);
        }
        showIframe.value = true;
      } else {
        showIframe.value = false;
      }
    };
    watch(
      () => route.path,
      () => {
        intoFreedoPath();
      },
    );
    onBeforeUpdate(() => {
      intoFreedoPath();
    });

    const getTask = () => {
      if (
        globalIframeRef.value &&
        globalIframeRef.value.contentWindow &&
        globalIframeRef.value.contentWindow.getTaskList
      ) {
        taskList.value = globalIframeRef.value.contentWindow.getTaskList();
        return globalIframeRef.value.contentWindow.getTaskList();
      }
      return {
        listCount: 0,
        fileNameList: [],
      };
    };

    // TODO 联调后验证是否需要加上此限制
    window.onbeforeunload = () => {
      if (getTask().listCount) {
        return '提示';
      }
    };

    setInterval(() => {
      getTask();
    }, 1000 * 60);
    return {
      src,
      globalIframeRef,
      showIframe,
    };
  },
});
</script>

<style lang="scss">
.global-iframe {
  width: calc(100vw - 270px);
  height: calc(100vh - 140px);
  position: absolute;
  right: 20px;
  bottom: 20px;
  background-color: rgba(0, 0, 0, 0.3);
  & > iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
}
</style>
