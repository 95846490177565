import schema from './schema';
import settings from './settings';
import project from './project';
import service from './service';
import tenant from './tenant';
import file from './file';
import auth from './auth';
import publish from './publish';
import app from './app';
import company from './company';
import repository from './repository';
import deploy from './deploy';
import cim from './cim';
import env from './env';

export default {
  schema,
  settings,
  project,
  service,
  tenant,
  file,
  auth,
  publish,
  app,
  company,
  repository,
  deploy,
  cim,
  env,
};
