
import { defineComponent, reactive } from 'vue';
// import AppLink from '@/layout/components/sideBar/Link.vue'
import Item from '@/layout/components/sideBar/Item.vue';
import { isExternal } from '@/utils/validate';
import path from 'path';
import { setLink } from '@/layout/messageCenter/linkto';

export default defineComponent({
  name: 'SidebarItem',
  components: {
    // AppLink,
    Item,
  },
  props: {
    item: {
      type: Object,
      default: () => ({
        meta: {
          title: '',
          icon: '',
          hidden: false,
        },
      }),
    },
    isNest: {
      type: Boolean,
      default: false,
    },
    basePath: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const onlyOneChild = reactive({ value: {} });

    const hasOwnShowingChild = (children = [], parent: any) => {
      const showChidren = children.filter((item) => {
        if ((item as any).meta.hidden) {
          return false;
        }
        onlyOneChild.value = item;
        return true;
      });

      if (showChidren.length === 1) {
        return true;
      }

      if (showChidren.length === 0) {
        onlyOneChild.value = { ...parent, noShowingChildren: true };
        return true;
      }
      return false;
    };

    const resolvePath = (routePath: string | undefined) => {
      if (!routePath) {
        return '/';
      }
      if (isExternal(routePath)) {
        return routePath;
      }
      if (isExternal(props.basePath)) {
        return props.basePath;
      }
      const rpath = path.resolve(props.basePath, routePath);
      // TODO 定位拼接菜单问题
      // if (rpath === '/service-repository/apply') {
      //   console.log(rpath, routePath, props.item);
      // }
      return rpath;
    };

    const logs = (res: any) => {
      console.log(res, 'this is log');
      // 设置侧边栏默认的路由值
      setLink(res.path);
      return res;
    };

    return {
      hasOwnShowingChild,
      resolvePath,
      onlyOneChild,
      logs,
    };
  },
});
