import axios, { AxiosRequestConfig, AxiosResponse, Canceler } from 'axios';
import { getToken, removeToken, getCookies } from '@/utils/todoToken';
import { ElMessage } from 'element-plus';
import router, { baseRoutes } from '@/router';
import Message from 'element-plus/es/el-message';
import { getEnvStorage } from './env-storage';
import { addRequestStorage } from './request-storage';

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 30000,
});

const TOKEN = 'token';
const PROJECT_ID = 'x-sa-project-id';
const TENANT_ID = 'x-sa-tenant-id';
const USER_ID = 'x-sa-user-id';

service.interceptors.request.use(
  (config: AxiosRequestConfig): AxiosRequestConfig => {
    const newConfig = { ...config };

    newConfig.cancelToken = new axios.CancelToken((cancel: Canceler) => {
      addRequestStorage(cancel);
    });

    if (getToken()) {
      newConfig.headers[TOKEN] = getToken();
    }
    newConfig.headers[PROJECT_ID] = getEnvStorage() || localStorage.getItem('projectId') || '';
    if (process.env.NODE_ENV === 'development') {
      newConfig.headers[TENANT_ID] = 1;
      newConfig.headers[USER_ID] = 83;
    }
    const item = {} as any;
    item[PROJECT_ID] = localStorage.getItem('projectId') || '';
    item.Cookie = getCookies();
    localStorage.setItem('HEADERS', JSON.stringify([item]));
    return newConfig;
  },
  (error) => Promise.reject(error),
);

service.interceptors.response.use(
  (response: AxiosResponse) => response.data,
  (error) => {
    if (axios.isCancel(error)) {
      return Promise.resolve();
    }
    const errorString = error.toString().toLocaleLowerCase();
    if (errorString.includes('timeout')) {
      Message.error('请求超时');
      return Promise.reject(error);
    }
    if (errorString.includes('network')) {
      Message.error('当前网络不可用');
      return Promise.reject(error);
    }
    // 如果没有error.response返回，则当做服务器异常处理
    if (!error.response) {
      ElMessage({
        message: error || '服务异常，请稍后处理',
        type: 'error',
        duration: 5 * 1000,
      });
      return Promise.reject(error);
    }
    if (error.response.status === 401) {
      localStorage.removeItem('authHash');
      let redirect = '';
      let currentPath = '/';
      if (router?.currentRoute?.value) {
        currentPath = router.currentRoute.value.path;
        redirect = router.currentRoute.value.query.redirect as string;
      }

      if (currentPath === '/reset-password') {
        //
      } else {
        const whiteList = baseRoutes.map((x) => x.path);
        if (whiteList.includes(currentPath)) {
          currentPath = '/';
        }

        if (redirect === '/user-detail') {
          redirect = '';
        }
        console.log(`/login?redirect=${redirect || currentPath}`);
        router.replace(`/login?redirect=${redirect || currentPath}`);
        if (error.response?.data?.code === 1112001) {
          ElMessage.error('您所输入的地址不存在或不可用，请联系管理员');
        }
      }
    }
    if (error.response.status === 403) {
      return ElMessage.error('暂无此权限，请联系管理员添加权限');
    }
    if (error.code === 'ECONNABORTED') {
      return Message.error('请求超时！');
    }

    const { data } = error.response; // status
    const { httpStatus, message } = data;
    // 错误状态处理
    if (httpStatus) {
      ElMessage({
        message,
        type: 'error',
        duration: 5 * 1000,
        onClose() {
          // 登录失效处理
          if (httpStatus === 401) {
            removeToken();
            location.reload();
          }
        },
      });
    }
    return Promise.reject(data);
  },
);

// eslint-disable-next-line
// @ts-ignore
export default service;
