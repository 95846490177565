
import { computed } from 'vue';
export default {
  name: 'SvgIcon',
  props: {
    iconClass: {
      type: String,
      default: () => '',
    },
    iconName: {
      type: String,
      default: '',
    },
  },
  setup(props: any) {
    const svgClass = computed(() => (props.iconClass ? `svg-icon ${props.iconClass}` : 'svg-icon'));
    const icon = computed(() => `#icon-${props.iconName}`);
    return {
      svgClass,
      icon,
    };
  },
};
