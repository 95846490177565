import { createRouter, createWebHistory, Router, RouteRecordRaw, createRouterMatcher, RouterMatcher } from 'vue-router';

import Layout from '@/layout/Index.vue';
import BlankLayout from '@/layout/BlankLayout.vue';
import { setRouterRef, getPermissionRoutes } from '@/layout/messageCenter/routerRef';
import { ElMessage } from 'element-plus';

const whiteTenant = 'weiling';
// 屏蔽组态
const openStudio = window?.location?.href?.includes(whiteTenant);

export const baseRoutes: Array<RouteRecordRaw> = [
  {
    path: '/no-right',
    name: 'noRight',
    component: () => import('@/views/no-right/Index.vue'),
    meta: {
      isRootLevel: false,
      title: '没有权限',
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/Index.vue'),
    meta: {
      isRootLevel: false,
      title: '登录',
    },
  },
  {
    path: '/not-found',
    name: 'notFound',
    component: () => import('@/views/not-found/Index.vue'),
    meta: {
      isRootLevel: false,
      title: '404',
    },
  },
  {
    path: '/router-loading',
    name: 'RouterLoading',
    component: () => import('@/views/router-loading/Index.vue'),
    meta: {
      isRootLevel: false,
      title: '信息获取中...',
    },
  },
  {
    path: '/reset-password',
    component: () => import('@/views/login/ResetPassword.vue'),
    name: 'resetPassword',
    meta: {
      title: '重置密码',
      icon: 'el-icon-eleme',
      isRootLevel: true,
      hidden: true,
    },
  },
  {
    path: '/forget-password',
    name: 'password',
    component: () => import('@/views/login/ForgetPassword.vue'),
    meta: {
      isRootLevel: false,
      title: '密码找回',
    },
  },
];

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/tenant-management',
    name: 'Dashboard',
    meta: {
      isRootLevel: true,
      hidden: true,
    },
  },

  // 应用管理中心
  {
    path: '/apps-center',
    name: 'AppsCenter',
    component: Layout,
    redirect: 'list',
    meta: {
      isRootLevel: true,
      id: 56,
      hidden: false,
      title: '应用管理中心',
      icon: 'app',
    },
    children: [
      {
        path: 'list',
        component: () => import('@/views/apps-center/list/Index.vue'),
        name: 'AppsCenterList',
        meta: {
          title: '应用管理中心',
          isRootLevel: false,
          id: 56,
          hidden: false,
        },
      },
    ],
  },

  // 应用编排中心
  {
    path: '/apps-arrange',
    name: 'AppsArrange',
    component: Layout,
    meta: {
      isRootLevel: true,
      id: 58,
      hidden: false,
      title: '应用编排中心',
      icon: 'application',
    },
    children: [
      {
        path: 'apps',
        component: () => import('@/views/apps-arrange/apps/Index.vue'),
        name: 'ArrangeApps',
        meta: {
          title: '应用系统组装',
          isRootLevel: false,
          id: 61,
          hidden: false,
        },
      },
      {
        path: 'apps/:env/:envId/:serviceId',
        component: () => import('@/views/application-module/application-management/ApplicationManDetail.vue'),
        name: 'ArrangeAppDetail',
        meta: {
          title: '应用系统详情',
          isRootLevel: false,
          activeMenu: '/apps-arrange/apps',
          showBackButton: true,
          id: 61,
          hidden: true,
        },
      },
      {
        path: 'visualize',
        component: () => import('@/views/configuration-studio-import/Index.vue'),
        name: 'ConfigurationImport',
        children: [
          {
            path: 'configuration-application',
            component: () => import('@/views/configuration-studio-import/App.vue'),
            name: 'ConfigurationApplication',
            children: [],
            meta: {
              title: '应用',
              isRootLevel: false,
              hidden: !openStudio,
              id: openStudio ? 23 : 1000,
            },
          },
          {
            path: 'configuration-data',
            component: () => import('@/views/configuration-studio-import/Data.vue'),
            name: 'ConfigurationData',
            children: [],
            meta: {
              title: '数据',
              isRootLevel: false,
              hidden: !openStudio,
              id: openStudio ? 23 : 1000,
            },
          },
        ],
        meta: {
          title: '可视化场景编排',
          isRootLevel: false,
          hidden: !openStudio,
          id: openStudio ? 23 : 1000,
        },
      },
      {
        path: '3d',
        component: () => import('@/views/apps-arrange/3d/Index.vue'),
        name: 'Arrange3d',
        meta: {
          title: '三维场景组装',
          isRootLevel: false,
          id: 60,
          hidden: false,
        },
      },
      // {
      //   path: 'modules',
      //   component: () => import('@/views/apps-center/arrange/modules/Index.vue'),
      //   name: 'ArrangeModules',
      //   meta: {
      //     title: '业务模块编排',
      //     isRootLevel: false,
      //     id: 55,
      //     hidden: false,
      //   },
      // },
    ],
  },

  // 服务管理中心
  {
    path: '/services-center',
    name: 'ServicesCenter',
    component: Layout,
    meta: {
      isRootLevel: true,
      id: 45,
      hidden: false,
      title: '服务管理中心',
      icon: 'service',
    },
    children: [
      {
        path: 'business',
        component: () => import('@/views/env/detail/Service.vue'),
        name: 'ServiceListPro',
        props: { isPro: true },
        meta: {
          title: '业务服务',
          isRootLevel: false,
          id: 46,
          hidden: false,
        },
      },
      {
        path: 'business/detail/:id',
        component: () => import('@/views/service-repository/detail/Index.vue'),
        name: 'ServiceListProDetail',
        props: { isPro: true },
        meta: {
          title: '业务服务详情',
          isRootLevel: false,
          hidden: true,
          activeMenu: '/services-center/business',
          showBackButton: true,
          id: 46,
          checkPath: 'active',
        },
      },
      {
        path: 'space',
        component: () => import('@/views/services-center/space/Index.vue'),
        name: 'ServicesCenterSpace',
        meta: {
          title: '空间服务',
          isRootLevel: false,
          id: 46,
          hidden: false,
        },
      },
      {
        path: 'space/detail/:id',
        component: () => import('@/views/services-center/space/spaceDetail/Index.vue'),
        name: 'SpaceDetail',
        meta: {
          title: '空间服务详情',
          isRootLevel: false,
          id: 46,
          hidden: true,
          activeMenu: '/services-center/space',
          checkPath: 'active',
          showBackButton: true,
        },
      },
      {
        path: 'register',
        component: () => import('@/views/services-center/space/components/ServiceRegister.vue'),
        name: 'ServicesCenterSpaceRegister',
        meta: {
          title: '空间服务注册',
          isRootLevel: false,
          id: 46,
          hidden: true,
          activeMenu: '/services-center/space',
        },
      },

      {
        path: 'lbs',
        // component: () => import('@/views/services-center/lbs/Index.vue'),
        component: () => import('@/views/service-management/iot/Index.vue'),
        name: 'ServicesCenterLbs',
        meta: {
          title: 'LBS数据服务',
          isRootLevel: false,
          id: 48,
          hidden: false,
        },
      },
      {
        path: 'algorithm',
        // component: () => import('@/views/services-center/algorithm/Index.vue'),
        component: () => import('@/views/service-management/iot/Index.vue'),
        name: 'ServicesCenterAlgorithm',
        meta: {
          title: '算法服务',
          isRootLevel: false,
          id: 49,
          hidden: false,
        },
      },
      {
        path: 'iot',
        // component: () => import('@/views/services-center/iot/Index.vue'),
        component: () => import('@/views/service-management/iot/Index.vue'),
        name: 'ServicesCenterIot',
        meta: {
          title: 'IOT服务',
          isRootLevel: false,
          id: 50,
          hidden: false,
        },
      },

      {
        path: 'serviceApply',
        component: () => import('@/views/services-center/service-apply/Index.vue'),
        name: 'ServicesCenterServiceApply',
        meta: {
          title: '服务审批管理',
          isRootLevel: false,
          id: 62,
          hidden: false,
        },
      },
      // 测试环境下的参数详情
      {
        path: 'business/:serviceId/interface/:apiId/params',
        component: () => import('@/views/service-management/api-params/ParamList.vue'),
        name: 'ServicesCenterApiParamList',
        meta: {
          title: '接口参数',
          isRootLevel: false,
          id: 46,
          hidden: true,
          activeMenu: '/services-center/business',
          showBackButton: true,
          checkPath: 'active',
          backRoute: (route: any, router: any) => {
            router.push({
              path: `/services-center/business/detail/${route.params.serviceId}`,
            });
          },
        },
      },
    ],
  },

  // 空间数据管理
  {
    path: '/space-data',
    name: 'SpaceData',
    component: Layout,
    meta: {
      isRootLevel: true,
      id: 41,
      hidden: false,
      title: '空间数据管理',
      icon: 'space',
    },
    children: [
      {
        path: '2d',
        component: () => import('@/views/space-data/2d/Index.vue'),
        name: 'SpaceData2d',
        meta: {
          title: '二维数据管理',
          isRootLevel: false,
          id: 42,
          hidden: false,
        },
      },
      {
        path: '3d',
        component: () => import('@/views/space-data/3d/Index.vue'),
        name: 'SpaceData3d',
        meta: {
          title: '三维数据管理',
          isRootLevel: false,
          id: 43,
          hidden: false,
        },
      },
      {
        path: '3d-govern',
        component: BlankLayout,
        name: 'Govern3D',
        meta: {
          title: '三维数据治理',
          isRootLevel: false,
          id: 44,
          hidden: false,
        },
        children: [
          {
            path: '3d-govern-source',
            component: () => import('@/views/space-data/3d-govern/Index.vue'),
            name: 'SpaceData3dSource',
            meta: {
              title: '源数据管理',
              isRootLevel: false,
              id: 44,
              hidden: false,
            },
          },
          {
            path: '3d-govern-cim',
            component: () => import('@/views/space-data/3d-govern/Index.vue'),
            name: 'SpaceData3dCim',
            meta: {
              title: '轻量化管理',
              isRootLevel: false,
              id: 44,
              hidden: false,
            },
          },
        ],
      },
      {
        path: 'other-service',
        component: BlankLayout,
        name: 'OtherService',
        meta: {
          title: '第三方服务',
          isRootLevel: false,
          id: 44,
          hidden: false,
        },
        children: [
          {
            path: 'arc-gis',
            component: () => import('@/views/space-data/3d-govern/Index.vue'),
            name: 'ArcGis',
            meta: {
              title: 'ArcGis服务',
              isRootLevel: false,
              id: 44,
              hidden: false,
            },
          },
          {
            path: 'super-map',
            component: () => import('@/views/space-data/3d-govern/Index.vue'),
            name: 'SuperMap',
            meta: {
              title: 'SuperMap服务',
              isRootLevel: false,
              id: 44,
              hidden: false,
            },
          },
        ],
      },
    ],
  },

  // 开发配置中心
  {
    path: '/',
    name: 'DevConfCenter',
    component: Layout,
    meta: {
      isRootLevel: true,
      id: 40,
      hidden: false,
      icon: 'dev-conf',
      title: '开发配置中心',
    },
    children: [
      {
        path: '/services',
        redirect: '/business',
        name: 'DevConfCenterServices',
        component: BlankLayout,
        meta: {
          title: '服务开发建模',
          isRootLevel: false,
          id: 7,
          node: true,
        },
        children: [
          {
            path: 'business',
            component: () => import('@/views/service-management/business-service/ServiceList.vue'),
            name: 'BusinessServiceList',
            meta: {
              title: '业务服务',
              isRootLevel: false,
              id: 7,
              hidden: false,
              checkPath: 'current',
            },
          },

          {
            path: 'iot',
            component: () => import('@/views/service-management/iot/Index.vue'),
            name: 'IotServiceList',
            meta: {
              title: 'IOT服务',
              isRootLevel: false,
              hidden: false,
            },
          },
          {
            path: 'merge-deploy',
            component: () => import('@/views/services-merge-deploy/Index.vue'),
            name: 'ServicesMergeDeploy',
            meta: {
              title: '服务合并部署',
              isRootLevel: false,
              hidden: false,
            },
          },
          {
            path: ':serviceId/interface/:apiId/params',
            component: () => import('@/views/service-management/api-params/ParamList.vue'),
            name: 'ServiceApiParamList',
            meta: {
              title: '接口参数',
              isRootLevel: false,
              hidden: true,
              showBackButton: true,
              backRoute: (route: any, router: any) => {
                router.replace(
                  `/services/business/edit/${route.params.serviceId}?detailName=${route.query.detailName}`,
                );
              },
            },
          },
          {
            path: 'business/detail/:id',
            component: () => import('@/views/service-repository/detail/Index.vue'),
            name: 'ServiceDetail',
            meta: {
              title: '业务服务详情',
              isRootLevel: false,
              hidden: true,
              activeMenu: '/services/business',
              showBackButton: true,
              id: 7,
              checkPath: 'active',
            },
          },
          {
            path: 'business/edit/:id',
            component: () => import('@/views/service-management/business-service/ServiceDetail.vue'),
            name: 'ServiceEdit',
            meta: {
              title: '服务详情',
              isRootLevel: false,
              hidden: true,
              // 回退路由
              activeMenu: '/services/business',
              showBackButton: true,
              id: 7,
              checkPath: 'active',
            },
          },
          {
            path: 'business/gwedit/:id',
            component: () => import('@/views/service-management/business-service/gwServiceDetail.vue'),
            name: 'gwServiceEdit',
            meta: {
              title: '服务详情',
              isRootLevel: false,
              hidden: true,
              // 回退路由
              activeMenu: '/services/business',
              showBackButton: true,
              id: 7,
              checkPath: 'active',
            },
          },
        ],
      },
      {
        path: '/apps',
        name: 'ApplicationManagement',
        redirect: '/micro',
        component: BlankLayout,
        meta: {
          title: '应用开发编排',
          isRootLevel: false,
          id: 23,
          node: true,
        },
        children: [
          {
            path: 'micro',
            component: () => import('@/views/application-module/application-list/Index.vue'),
            name: 'ApplicationMicroList',
            children: [],
            meta: {
              title: '微前端模块',
              isRootLevel: false,
              hidden: false,
              id: 23,
            },
          },
          {
            path: 'main',
            component: () => import('@/views/application-module/application-management/Index.vue'),
            name: 'ApplicationMainList',
            children: [],
            meta: {
              title: '主应用列表',
              isRootLevel: false,
              hidden: false,
              id: 23,
            },
          },
          {
            path: 'main/:id',
            component: () => import('@/views/application-module/application-management/ApplicationManDetail.vue'),
            name: 'ApplicationMainDetail',
            meta: {
              title: '应用编排',
              isRootLevel: false,
              id: 23,
              hidden: true,
              activeMenu: '/apps/main',
              showBackButton: true,
              checkPath: 'active',
            },
          },
          {
            path: 'micro/:id',
            component: () => import('@/views/application-module/application-list/ApplicationDetail.vue'),
            name: 'ApplicationMicroDetail',
            meta: {
              title: '应用详情',
              isRootLevel: false,
              id: 23,
              hidden: true,
              activeMenu: '/apps/micro',
              showBackButton: true,
              checkPath: 'active',
            },
          },
        ],
      },
      {
        path: '/env',
        name: 'env',
        redirect: '/env/test',
        component: BlankLayout,
        meta: {
          title: '运行环境',
          isRootLevel: false,
          id: 39,
          node: true,
        },
        children: [
          {
            path: 'test/:id',
            component: () => import(/* webpackChunkName: "env-detail" */ '@/views/env/detail/Index.vue'),
            name: 'EnvTestDetail',
            meta: {
              title: '测试环境详情',
              isRootLevel: false,
              id: 39,
              hidden: true,
              activeMenu: '/env/test',
              showBackButton: true,
              checkPath: 'active',
            },
          },
          {
            path: 'prod/:id',
            component: () => import(/* webpackChunkName: "env-detail" */ '@/views/env/detail/Index.vue'),
            name: 'EnvProdDetail',
            meta: {
              title: '生产环境详情',
              isRootLevel: false,
              id: 57,
              hidden: true,
              activeMenu: '/env/prod',
              showBackButton: false,
              checkPath: 'active',
            },
          },
          {
            path: 'bugfix',
            component: () => import('@/views/service-management/iot/Index.vue'),
            name: 'EnvBugfixList',
            children: [],
            meta: {
              title: 'bugfix',
              isRootLevel: false,
              hidden: false,
              id: 39,
            },
          },
          {
            path: 'test',
            component: () => import('@/views/env/list/Index.vue'),
            name: 'EnvTestList',
            children: [],
            meta: {
              title: '测试',
              isRootLevel: false,
              hidden: false,
              id: 39,
            },
          },
          {
            path: 'prod',
            component: () => import('@/views/env/list/Index.vue'),
            name: 'EnvProdList',
            children: [],
            meta: {
              title: '生产',
              isRootLevel: false,
              hidden: false,
              id: 57,
            },
          },
          // 环境下的服务详情
          {
            path: 'test/:envId/service/:serviceId',
            component: () => import('@/views/service-management/business-service/ServiceDetail.vue'),
            name: 'EnvTestServiceDetail',
            meta: {
              title: '服务详情',
              isRootLevel: false,
              id: 39,
              hidden: true,
              activeMenu: '/env/test',
              showBackButton: true,
              checkPath: 'active',
              backRoute: (route: any, router: any) => {
                const path = `/env/test/${route.params.envId}`;
                router.push({
                  path,
                  query: {
                    page: route.query.pp,
                    tab: 'service',
                  },
                });
              },
            },
          },
          // 环境下的服务详情
          {
            path: 'test/:envId/gwedit/:serviceId',
            component: () => import('@/views/service-management/business-service/gwServiceDetail.vue'),
            name: 'EnvTestgwServiceDetail',
            meta: {
              title: '服务详情',
              isRootLevel: false,
              id: 39,
              hidden: true,
              activeMenu: '/env/test',
              showBackButton: true,
              checkPath: 'active',
              backRoute: (route: any, router: any) => {
                const path = `/env/test/${route.params.envId}`;
                router.push({
                  path,
                  query: {
                    page: route.query.pp,
                    tab: 'service',
                  },
                });
              },
            },
          },
          // 环境下的主应用详情
          {
            path: 'test/:envId/main-app/:serviceId',
            component: () => import('@/views/application-module/application-management/ApplicationManDetail.vue'),
            name: 'EnvTestMainAppDetail',
            meta: {
              title: '主应用详情',
              isRootLevel: false,
              id: 39,
              hidden: true,
              activeMenu: '/env/test',
              showBackButton: true,
              checkPath: 'active',
              backRoute: (route: any, router: any) => {
                const path = `/env/test/${route.params.envId}`;
                router.push({
                  path,
                  query: {
                    page: route.query.pp,
                    tab: 'main-app',
                  },
                });
              },
            },
          },
          // 测试环境下的微应用详情
          {
            path: 'test/:envId/micro-app/:serviceId',
            component: () => import('@/views/application-module/application-list/ApplicationDetail.vue'),
            name: 'EnvTestMicroAppDetail',
            meta: {
              title: '微应用详情',
              isRootLevel: false,
              id: 39,
              hidden: true,
              activeMenu: '/env/test',
              showBackButton: true,
              checkPath: 'active',
              backRoute: (route: any, router: any) => {
                const path = `/env/test/${route.params.envId}`;
                router.push({
                  path,
                  query: {
                    page: route.query.pp,
                    tab: 'micro-app',
                  },
                });
              },
            },
          },
          // 测试环境下的参数详情
          {
            path: 'test/:envId/service/:serviceId/interface/:apiId/params',
            component: () => import('@/views/service-management/api-params/ParamList.vue'),
            name: 'EnvTestServiceApiParamList',
            meta: {
              title: '接口参数',
              isRootLevel: false,
              id: 39,
              hidden: true,
              activeMenu: '/env/test',
              showBackButton: true,
              checkPath: 'active',
              backRoute: (route: any, router: any) => {
                router.push({
                  path: `/env/test/${route.params.envId}/service/${route.params.serviceId}`,
                });
              },
            },
          },
          // 环境下的服务详情
          {
            path: 'prod/:envId/service/:serviceId',
            component: () => import('@/views/service-management/business-service/ServiceDetail.vue'),
            name: 'EnvProdServiceDetail',
            meta: {
              title: '服务详情',
              isRootLevel: false,
              id: 57,
              hidden: true,
              activeMenu: '/env/prod',
              showBackButton: true,
              checkPath: 'active',
              backRoute: (route: any, router: any) => {
                const path = `/env/prod/${route.params.envId}`;
                router.push({
                  path,
                  query: {
                    page: route.query.pp,
                    tab: 'service',
                  },
                });
              },
            },
          },
          // 生产环境下的网关服务详情
          {
            path: 'prod/:envId/gwedit/:serviceId',
            component: () => import('@/views/service-management/business-service/gwServiceDetail.vue'),
            name: 'EnvProdgwServiceDetail',
            meta: {
              title: '服务详情',
              isRootLevel: false,
              id: 57,
              hidden: true,
              activeMenu: '/env/prod',
              showBackButton: true,
              checkPath: 'active',
              backRoute: (route: any, router: any) => {
                const path = `/env/prod/${route.params.envId}`;
                router.push({
                  path,
                  query: {
                    page: route.query.pp,
                    tab: 'service',
                  },
                });
              },
            },
          },
          // 生产环境下的主应用详情
          {
            path: 'prod/:envId/main-app/:serviceId',
            component: () => import('@/views/application-module/application-management/ApplicationManDetail.vue'),
            name: 'EnvProdMainAppDetail',
            meta: {
              title: '主应用详情',
              isRootLevel: false,
              id: 57,
              hidden: true,
              activeMenu: '/env/prod',
              showBackButton: true,
              checkPath: 'active',
              backRoute: (route: any, router: any) => {
                const path = `/env/prod/${route.params.envId}`;
                router.push({
                  path,
                  query: {
                    page: route.query.pp,
                    tab: 'main-app',
                  },
                });
              },
            },
          },
          // 生产环境下的微应用详情
          {
            path: 'prod/:envId/micro-app/:serviceId',
            component: () => import('@/views/application-module/application-list/ApplicationDetail.vue'),
            name: 'EnvProdMicroAppDetail',
            meta: {
              title: '微应用详情',
              isRootLevel: false,
              id: 57,
              hidden: true,
              activeMenu: '/env/prod',
              showBackButton: true,
              checkPath: 'active',
              backRoute: (route: any, router: any) => {
                const path = `/env/prod/${route.params.envId}`;
                router.push({
                  path,
                  query: {
                    page: route.query.pp,
                    tab: 'micro-app',
                  },
                });
              },
            },
          },
          // 生产环境下的参数详情
          {
            path: 'prod/:envId/service/:serviceId/interface/:apiId/params',
            component: () => import('@/views/service-management/api-params/ParamList.vue'),
            name: 'EnvProdServiceApiParamList',
            meta: {
              title: '接口参数',
              isRootLevel: false,
              id: 57,
              hidden: true,
              activeMenu: '/env/prod',
              showBackButton: true,
              checkPath: 'active',
              backRoute: (route: any, router: any) => {
                router.push({
                  path: `/env/prod/${route.params.envId}/service/${route.params.serviceId}`,
                });
              },
            },
          },
        ],
      },
      {
        path: 'service-monitor-center',
        component: () => import('@/views/space-data/3d/Index.vue'),
        name: 'ServiceMonitorCenter',
        meta: {
          title: '服务监控中心',
          isRootLevel: false,
          id: 43,
          hidden: false,
        },
      },
    ],
  },

  // 产品仓库
  {
    path: '/service-repository',
    name: '产品仓库',
    component: Layout,
    redirect: 'platform',
    meta: {
      title: '产品仓库',
      icon: 'service-repository',
      isRootLevel: true,
      hidden: false,
      node: true,
      id: 24,
    },
    children: [
      {
        path: 'platform/:id',
        component: () => import('@/views/service-repository/detail/Index.vue'),
        name: 'RepositoryPlatformDetail',
        meta: {
          title: '服务详情',
          isRootLevel: false,
          hidden: true,
          activeMenu: '/service-repository/platform',
          showBackButton: true,
          id: 25,
        },
      },
      {
        path: 'platform/app/:id',
        component: () => import('@/views/service-repository/detail/AppDetail.vue'),
        name: 'AppRepositoryPlatformDetailApp',
        meta: {
          title: '应用详情',
          isRootLevel: false,
          hidden: true,
          activeMenu: '/service-repository/platform',
          showBackButton: true,
          id: 25,
        },
      },
      {
        path: 'tenant',
        component: () => import('@/views/service-repository/tenant/Index.vue'),
        name: 'TenantRepositoryList',
        redirect: 'shared',
        children: [
          {
            path: 'shared',
            component: () => import('@/views/service-repository/tenant/Shared.vue'),
            name: 'SharedList',
            children: [],
            meta: {
              title: '产品共享',
              isRootLevel: false,
              hidden: false,
              id: 27,
            },
          },
          {
            path: 'distribute',
            component: () => import('@/views/service-repository/tenant/Distribute.vue'),
            name: 'DistributeList',
            children: [],
            meta: {
              title: '产品下发',
              isRootLevel: false,
              hidden: false,
              id: 28,
            },
          },
        ],
        meta: {
          title: '租户仓库',
          isRootLevel: false,
          hidden: false,
          id: 26,
        },
      },
      {
        path: 'platform',
        component: () => import('@/views/service-repository/platform/Index.vue'),
        name: 'PublicRepositoryList',
        children: [],
        meta: {
          title: '平台仓库',
          isRootLevel: false,
          hidden: false,
          id: 25,
        },
      },
      {
        path: 'tenant/shared/:id',
        component: () => import('@/views/service-repository/detail/Index.vue'),
        name: 'RepositoryTenantSharedDetail',
        meta: {
          title: '服务详情',
          isRootLevel: false,
          hidden: true,
          activeMenu: '/service-repository/tenant/shared',
          showBackButton: true,
          id: 27,
        },
      },
      {
        path: 'tenant/distribute/:id',
        component: () => import('@/views/service-repository/detail/Index.vue'),
        name: 'RepositoryTenantDistributeDetail',
        meta: {
          title: '服务详情',
          isRootLevel: false,
          hidden: true,
          activeMenu: '/service-repository/tenant/distribute',
          showBackButton: true,
          id: 28,
        },
      },
      {
        path: 'tenant/shared/app/:id',
        component: () => import('@/views/service-repository/detail/AppDetail.vue'),
        name: 'RepositoryTenantSharedDetailApp',
        meta: {
          title: '应用详情',
          isRootLevel: false,
          hidden: true,
          activeMenu: '/service-repository/tenant/shared',
          showBackButton: true,
          id: 27,
        },
      },
      {
        path: 'tenant/distribute/app/:id',
        component: () => import('@/views/service-repository/detail/AppDetail.vue'),
        name: 'RepositoryTenantDistributeDetailApp',
        meta: {
          title: '应用详情',
          isRootLevel: false,
          hidden: true,
          activeMenu: '/service-repository/tenant/distribute',
          showBackButton: true,
          id: 28,
        },
      },
      {
        path: '/service-repository/deploy',
        name: 'Deploy',
        component: BlankLayout,
        meta: {
          title: '发布管理',
          isRootLevel: false,
          hidden: false,
          id: 29,
          node: true,
        },
        children: [
          {
            path: '/service-repository/deploy/apply',
            component: () => import('@/views/deploy/Apply.vue'),
            name: 'DeployApply',
            meta: {
              title: '发布申请',
              isRootLevel: false,
              id: 30,
              checkPath: 'current',
            },
          },
          {
            path: '/service-repository/deploy/review',
            component: () => import('@/views/deploy/Review.vue'),
            name: 'DeployReview',
            meta: {
              title: '发布审核',
              isRootLevel: false,
              id: 31,
              checkPath: 'current',
            },
          },
          {
            path: '/service-repository/deploy/detail/:id',
            component: () =>
              import(/* webpackChunkName: "repository-detail" */ '@/views/service-repository/detail/Index.vue'),
            name: 'RepositoryDetail',
            meta: {
              title: '服务详情',
              isRootLevel: false,
              hidden: true,
              showBackButton: true,
              activeMenu: '/deploy',
              id: 32,
              checkPath: 'active',
            },
          },
        ],
      },
    ],
  },

  {
    path: '/tenant-management',
    redirect: '/tenant-detail',
    name: 'TenantManagement',
    component: Layout,
    meta: {
      isRootLevel: true,
      id: 2,
      hidden: false,
      title: '租户管理',
      icon: 'tenant',
    },
    children: [
      {
        path: 'tenant-detail',
        component: () => import(/* webpackChunkName: "tenant" */ '@/views/tenant-management/Index.vue'),
        name: 'TenantDetail',
        meta: {
          title: '租户详情',
          isRootLevel: false,
          id: 10,
          hidden: false,
        },
      },
      {
        path: '/company-manage',
        name: 'Company',
        component: BlankLayout,
        meta: {
          title: '公司管理',
          isRootLevel: false,
          id: 3,
          node: true,
        },
        children: [
          {
            path: 'person',
            component: () => import('@/views/company-manage/Person.vue'),
            name: 'Person',
            meta: {
              title: '人员管理',
              isRootLevel: false,
              id: 11,
            },
          },
          {
            path: 'department',
            component: () => import('@/views/company-manage/Department.vue'),
            name: 'Department',
            meta: {
              title: '部门管理',
              isRootLevel: false,
              id: 12,
            },
          },
        ],
      },
      {
        path: '/project-management/project-list',
        component: () => import('@/views/project-management/ProjectList.vue'),
        name: 'ProjectList',
        meta: {
          title: '项目列表',
          isRootLevel: false,
          id: 4,
          hidden: false,
        },
      },
      {
        path: '/project-management/project-detail/:id',
        component: () => import('@/views/project-management/ProjectDetail.vue'),
        name: 'ProjectDetail',
        meta: {
          title: '项目详情',
          isRootLevel: false,
          hidden: true,
          activeMenu: '/project-management/project-list',
          id: 4,
          showBackButton: true,
        },
        props: true,
      },
      {
        path: '/settings',
        name: 'Settings',
        component: BlankLayout,
        meta: {
          title: '配置管理',
          isRootLevel: false,
          id: 8,
          node: true,
        },
        children: [
          {
            path: 'classification',
            component: () => import('@/views/conf-management/Classification.vue'),
            name: 'Classification',
            meta: {
              title: '分类配置',
              isRootLevel: false,
              id: 17,
            },
          },
          {
            path: 'tag',
            component: () => import('@/views/conf-management/Tag.vue'),
            name: 'Tag',
            meta: {
              title: '标签配置',
              isRootLevel: false,
              id: 18,
            },
          },
          {
            path: 'datatype',
            component: () => import('@/views/conf-management/DataType.vue'),
            name: 'DataType',
            meta: {
              title: '数据类型',
              isRootLevel: false,
              id: 19,
            },
          },
          {
            path: 'datatype/addedit',
            component: () => import('@/views/conf-management/DataTypeEdit.vue'),
            name: 'DataTypeAddEdit',
            meta: {
              title: '新建数据类型',
              hidden: true,
              isRootLevel: false,
              id: 19,
              activeMenu: '/settings/datatype',
            },
          },
          {
            path: 'datatype/edit/:id',
            component: () => import('@/views/conf-management/DataTypeEdit.vue'),
            name: 'DataTypeEdit',
            meta: {
              title: '编辑数据类型',
              hidden: true,
              isRootLevel: false,
              id: 19,
              activeMenu: '/settings/datatype',
            },
          },
          {
            path: 'config',
            component: () => import('@/views/conf-management/Config.vue'),
            name: 'Config',
            meta: {
              title: '通用配置',
              isRootLevel: false,
              id: 20,
            },
          },
          {
            path: 'tenant-parameters',
            component: () => import('@/views/conf-management/TenantParameters.vue'),
            name: 'TenantParameters',
            meta: {
              title: '租户参数',
              isRootLevel: false,
              id: 21,
            },
          },
        ],
      },
    ],
  },

  // 服务资源中心
  {
    path: '/service-resource-center',
    name: 'ServiceResourceCenter',
    component: () => import('@/views/service-resource-center/Index2.vue'),
    meta: {
      isRootLevel: false,
      title: '服务资源管理中心',
      // id: 56,
    },
  },

  // 服务资源中心详情
  {
    path: '/service-resource-center/:id',
    component: () => import('@/views/service-resource-center/Detail.vue'),
    name: 'SourceCenterDetail',
    meta: {
      title: '空间服务详情',
      isRootLevel: true,
      hidden: true,
      // id: 56,
    },
  },
  {
    path: '/service-resource-center/user-center',
    component: () => import('@/views/service-resource-center/apply-management/Index.vue'),
    name: 'SpaceUserCenter',
    redirect: '/service-resource-center/user-center/user-info',
    meta: {
      title: '用户信息',
      isRootLevel: true,
      hidden: true,
    },
    children: [
      {
        path: '/service-resource-center/user-center/user-info',
        component: () => import('@/views/service-resource-center/apply-management/userInfo.vue'),
        name: 'SpaceUserInfo',
        meta: {
          title: '用户信息',
          isRootLevel: false,
          hidden: true,
        },
      },
      {
        path: '/service-resource-center/user-center/my-token',
        component: () => import('@/views/service-resource-center/apply-management/Token.vue'),
        name: 'SpaceUserToken',
        meta: {
          title: '我的Token',
          isRootLevel: false,
          hidden: true,
        },
      },
      {
        path: '/service-resource-center/user-center/service-apply',
        component: () => import('@/views/service-resource-center/apply-management/Service.vue'),
        name: 'SpaceUserService',
        meta: {
          title: '我的申请',
          isRootLevel: false,
          hidden: true,
        },
      },
    ],
  },
  {
    path: '/user-management',
    redirect: '/user-detail',
    name: 'UserManagement',
    component: Layout,
    meta: {
      isRootLevel: true,
      hidden: true,
      node: true,
    },
    children: [
      {
        path: 'user-detail',
        component: () => import(/* webpackChunkName: "tenant" */ '@/views/user-management/Index.vue'),
        name: 'UserCenter',
        meta: {
          title: '个人中心',
          icon: 'el-icon-eleme',
          isRootLevel: false,
          hidden: true,
        },
      },
    ],
  },
];

export const reCreateRouter = (routes: Array<RouteRecordRaw>): Router =>
  createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
  });

export const reCreateRouterMatcher = (routes: Array<RouteRecordRaw>): RouterMatcher => createRouterMatcher(routes, {});

// eslint-disable-next-line
let router = reCreateRouter([...baseRoutes]);

export const resetRouter = (routes: Array<RouteRecordRaw>): void => {
  // router = reCreateRouter(routes);
  router.getRoutes().forEach((x: any) => {
    router.removeRoute(x.name);
  });
  routes.forEach((x) => {
    router.addRoute(x);
  });
  setRouterRef(reCreateRouter(routes));
};

export const resetPremissionRouter = (callback?: any) => {
  const routed = getPermissionRoutes([...routes, ...baseRoutes]);
  routed[0].redirect = routed[1].path;
  resetRouter(routed);
  setRouterRef(router);
  // eslint-disable-next-line no-unused-expressions
  callback?.();
};

// resetPremissionRouter();

export const alloverRouter = () => reCreateRouter([...baseRoutes, ...routes]);

router.beforeEach((to, from, next) => {
  // 切换页面过程中清空所有页面提示，防止不属于本页面的提示出现
  ElMessage.closeAll();
  next();
});

export default router;
