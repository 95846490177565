export default {
  GET_SERVICE_FOR_TEST: ['repoManage/getServiceForTest'],
  GET_APP_FOR_TEST: ['repoManage/getAppForTest'],
  DEPLOY_TO_TEST: ['repository/deployToTestProject/_'],
  GET_SERVICE_FOR_DEPLOY: ['repoManage/getServiceForProd'],
  GET_APP_FOR_DEPLOY: ['repoManage/getAppForProd'],
  GET_DEPLOY_LIST: ['/deploy/list'],
  ADD_DEPLOY: ['/deploy/apply'],
  UPDATE_DEPLOY: ['/deploy/edit/_'],
  DELETE_DEPLOY: ['/deploy/delete/_'],
  GET_AUDIT_LIST: ['deploy/audit/list'],
  AUDIT_DEPLOY: ['deploy/audit/_'],
  DEPLOY_TO_PROD: ['repository/deployToProdProject/_'],
  UPGRADE_AVAILABLE: ['env/upgradeAvailable/_'],
  UPGRADE_VERSIONS: ['env/upgradeVersions/_'],
  UPGRADE_INSTANCE: ['env/doUpgrade/_'],
  GET_USER: ['/deploy/applicant/search'],
};
