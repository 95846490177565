// 0 表示 api地址， // 1 表示mock地址
export default {
  GET_DATA_TYPES: ['/settings/data-types', '/mock/settings/data-types.json'],
  GET_DATA_TYPES_ALL: ['/settings/data-types/all', '/mock/settings/data-types.json'],
  ADD_DATA_TYPE: ['/settings/data-types', '/mock/settings/datatype-create.json'],
  UPDATE_DATA_TYPE: ['/settings/data-types/_', '/mock/settings/datatype-update.json'],
  DELETE_DATA_TYPE: ['/settings/data-types/delete', '/mock/settings/datatype-delete.json'],
  GET_DATA_TYPES_DETAIL: ['/settings/data-types/_', '/mock/settings/datatype-detail.json'],
  GET_TAGS_ALL: ['settings/tags/all', '/mock/settings/all-tags.json'],
  GET_TAGS: ['settings/tags', '/mock/settings/tag-list.json'],
  ADD_TAG: ['settings/tags', '/mock/settings/all-tags.json'],
  UPDATE_TAG: ['settings/tags/_', '/mock/settings/all-tags.json'],
  DELETE_TAG: ['settings/tags/delete', '/mock/settings/all-tags.json'],
  GET_CATEGORIES: ['settings/categories/tree', '/mock/settings/category-options.json'],
  GET_CLASSIFICATION_LIST: ['/settings/categories/tree', '/mock/settings/classification-list.json'],
  GET_CLASSIFICATION_DETAIL: ['/settings/categories/_', '/mock/settings/classification-detail.json'],
  DELETE_CLASSIFICATION: ['/settings/categories/delete/_', '/mock/settings/classification-delete.json'],
  UPDATE_CLASSIFICATION: ['/settings/categories/_', '/mock/settings/classification-update.json'],
  ADD_CLASSIFICATION: ['/settings/categories', '/mock/settings/classification-update.json'],
  GET_ALL_TEMPLATE: ['/settings/templates/all', '/mock/settings/all-templates.json'],
  GET_DICTIONARY_TYPES: ['/settings/dictionaries/type', 'mock/settings/dictionary-types.json'],
  GET_DICTIONARY_DETAIL: ['/settings/dictionaries/_', 'mock/settings/dictionary-detail.json'],
  GET_REGIONS: ['/settings/regions', 'mock/settings/regions.json'],
  GET_CONFIG: ['/settings/config', 'mock/settings/config.json'],
  GET_SERVICECONFIG: ['/settings/config/_', 'mock/settings/service-config.json'],
  ADD_CONFIG: ['/settings/config', 'mock/settings/config-add.json'],
  UPDATE_CONFIG: ['/settings/config/_', 'mock/settings/config-update.json'],
  DELETE_CONFIG: ['/settings/config/delete/_', 'mock/settings/config-delete.json'],
  GET_CONFIG_HISTORY: ['/settings/config/history/_', 'mock/settings/config-history.json'],
  DELIVERY_CONFIG: ['/settings/config/publish/_', 'mock/settings/config-delete.json'],
  CHECK_KEY_RULE: ['/settings/config/usable', 'mock/settings/check-rule.json'],
  CHECK_TAG_RULE: ['/settings/tags/usable', 'mock/settings/checkTag-rule.json'],
};
