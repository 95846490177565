import { Canceler } from 'axios';
import { Storage } from './storage';

const REQUEST_STORAGE_KEY = 'sa-axios-cancel-token';

export const getRequestStorage = (): Canceler[] => Storage.memory.getItem(REQUEST_STORAGE_KEY) || [];

export const setRequestStorage = (cancelToken: Canceler[]) => Storage.memory.setItem(REQUEST_STORAGE_KEY, cancelToken);

export const addRequestStorage = (cancelToken: Canceler) => {
  const cancelTokens = getRequestStorage();
  cancelTokens.push(cancelToken);
  setRequestStorage(cancelTokens);
};

export const clearRequestStorage = () => {
  const cancelTokens = getRequestStorage();
  cancelTokens.forEach((cancel: Canceler) => cancel?.());
  setRequestStorage([]);
};
