import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "layout" }
const _hoisted_2 = { class: "layout-head" }
const _hoisted_3 = { class: "layout-main" }
const _hoisted_4 = { class: "layout-sidebar" }
const _hoisted_5 = { class: "layout-container" }
const _hoisted_6 = { class: "layout-viewport" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_nav_bar = _resolveComponent("nav-bar")!
  const _component_side_bar = _resolveComponent("side-bar")!
  const _component_blank_header = _resolveComponent("blank-header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", _hoisted_2, [
      _createVNode(_component_nav_bar)
    ]),
    _createElementVNode("main", _hoisted_3, [
      _createElementVNode("aside", _hoisted_4, [
        _createVNode(_component_side_bar)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_el_scrollbar, null, {
          default: _withCtx(() => [
            _createVNode(_component_blank_header),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_router_view)
            ])
          ]),
          _: 1
        })
      ])
    ])
  ]))
}