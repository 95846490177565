
import { defineComponent } from 'vue';
import SideBar from './components/sideBar/Index.vue';
import NavBar from './components/navBar/Index.vue';
import publicStyle from '@/styles/layout.scss';
import BlankHeader from '@/components/blank-header/Index.vue';

export default defineComponent({
  name: 'Layout',
  components: {
    SideBar,
    NavBar,
    BlankHeader,
  },
  setup() {
    return {
      publicStyle,
    };
  },
});
