const getBaseStorage = (type: 'localStorage' | 'sessionStorage') => ({
  getItem(key: string) {
    const value = window[type].getItem(key);
    if (value !== undefined) {
      return JSON.parse(value as string);
    }
  },
  setItem(key: string, value: any) {
    return window[type].setItem(key, JSON.stringify(value));
  },
  removeItem(key: string) {
    return window[type].removeItem(key);
  },
  clear() {
    return window[type].clear();
  },
});

const MEMORY_CACHES = new Map();
/**
 * storage各种类型封装
 *
 */
export const Storage = {
  local: getBaseStorage('localStorage'),
  session: getBaseStorage('sessionStorage'),
  memory: {
    getItem(key: string) {
      return MEMORY_CACHES.get(key);
    },
    setItem(key: string, value: any) {
      return MEMORY_CACHES.set(key, value);
    },
    removeItem(key: string) {
      return MEMORY_CACHES.delete(key);
    },
    clear() {
      return MEMORY_CACHES.clear();
    },
  },
};
