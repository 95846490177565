<template>
  <el-col :xs="24" :sm="span" class="sa-descriptions__item">
    <span class="sa-descriptions__item_label" :style="labelStyle">{{ label }}</span>
    <span class="sa-descriptions__item_value">
      <slot />
    </span>
  </el-col>
</template>
<script>
import { computed, inject } from 'vue';
export default {
  name: 'SaDescriptionsItem',
  props: {
    label: {
      type: String,
      default: '',
    },
    labelWidth: {
      type: [String, Number],
      default: 100,
    },
  },
  inject: ['SA_DESCRIPTIONS'],
  setup(props) {
    const span = computed(() => {
      const parentProps = inject('SA_DESCRIPTIONS');
      return 24 / parentProps.column;
    });
    const labelStyle = computed(() => {
      let numberWidth = Number(props.labelWidth);
      if (!isNaN(numberWidth)) {
        numberWidth = `${props.labelWidth}px`;
      } else {
        numberWidth = props.labelWidth;
      }
      return {
        width: numberWidth,
      };
    });
    return {
      span,
      labelStyle,
    };
  },
};
</script>
<style lang="scss" scoped>
.sa-descriptions__item {
  display: flex;
  align-items: flex-start;
  margin: 5px 0;
  &_label {
    flex-shrink: 0;
    padding: 0 12px 0 0;
    opacity: 0.8;
  }
  &_value {
    flex: 1;
  }
}
</style>
