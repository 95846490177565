import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_item = _resolveComponent("item")!
  const _component_el_menu_item = _resolveComponent("el-menu-item")!
  const _component_sidebar_item = _resolveComponent("sidebar-item", true)!
  const _component_el_submenu = _resolveComponent("el-submenu")!

  return (!_ctx.item.meta.hidden)
    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
        (_ctx.hasOwnShowingChild(_ctx.item.children, _ctx.item) && (!_ctx.onlyOneChild.children || _ctx.onlyOneChild.noShowingChildren))
          ? (_openBlock(), _createBlock(_component_el_menu_item, {
              key: 0,
              index: _ctx.resolvePath(_ctx.onlyOneChild.value.path),
              class: _normalizeClass({ 'submenu-title-noDropdown': !_ctx.isNest })
            }, {
              default: _withCtx(() => [
                _createVNode(_component_item, {
                  icon: _ctx.onlyOneChild.value.meta.icon || (_ctx.item.meta && _ctx.item.meta.icon),
                  title: _ctx.onlyOneChild.value.meta.title,
                  onClick: _ctx.onlyOneChild.value
                }, null, 8, ["icon", "title", "onClick"])
              ]),
              _: 1
            }, 8, ["index", "class"]))
          : (_openBlock(), _createBlock(_component_el_submenu, {
              key: 1,
              ref: "subMenu",
              index: _ctx.resolvePath(_ctx.item.path),
              "popper-append-to-body": ""
            }, {
              title: _withCtx(() => [
                (_ctx.item.meta)
                  ? (_openBlock(), _createBlock(_component_item, {
                      key: 0,
                      icon: _ctx.item.meta && _ctx.item.meta.icon,
                      title: _ctx.item.meta.title
                    }, null, 8, ["icon", "title"]))
                  : _createCommentVNode("", true)
              ]),
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.item.children, (child) => {
                  return (_openBlock(), _createBlock(_component_sidebar_item, {
                    key: child.path,
                    "is-nest": true,
                    item: child,
                    "base-path": _ctx.resolvePath(_ctx.item.path)
                  }, null, 8, ["item", "base-path"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["index"]))
      ], 64))
    : _createCommentVNode("", true)
}