
import { defineComponent, computed, getCurrentInstance, ref } from 'vue';
import SidebarItem from '@/layout/components/sideBar/SidebarItem.vue';
import menuVariables from '@/styles/menu.scss';
import { getComputedRoutes } from '@/layout/messageCenter/routerRef';
import { getLink } from '@/layout/messageCenter/linkto';

export default defineComponent({
  name: 'sideBar',
  components: {
    SidebarItem,
  },
  setup() {
    const isCollapse = computed(() => false);
    // eslint-disable-next-line
    // @ts-ignore
    const { proxy } = getCurrentInstance();
    const activeMenu = computed(() => {
      // eslint-disable-next-line
      // @ts-ignore
      const route = proxy.$route;
      const { meta, path } = route;
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    });
    const permissionRoutes = ref(getComputedRoutes() as any);

    return {
      menuVariables,
      isCollapse,
      activeMenu,
      permissionRoutes,
      getLink,
    };
  },
});
