import { ref } from 'vue';
export const pathMap = {
  '/space-data/2d': {
    path: '/admin/wmts',
    name: '源数据管理-二维',
  },
  '/space-data/3d': {
    path: '/admin/pmts',
    name: '源数据管理-三维',
  },
  '/space-data/3d-govern/3d-govern-cim': {
    path: '/admin/manageChild/lightweightpmts',
    name: '轻量化三维',
  },
  '/space-data/3d-govern/3d-govern-source': {
    path: '/admin/manageChild/pmts',
    name: '三维源数据管理',
  },
  '/service-monitor-center': {
    path: '/admin/serveMonitor',
    name: '服务监控中心',
  },
  '/apps-arrange/3d': {
    path: '/admin/scene/list',
    name: '三维场景组装',
  },
  '/space-data/other-service/arc-gis': {
    path: '/admin/arcGis',
    name: 'ArcGis服务',
  },
  '/space-data/other-service/super-map': {
    path: '/admin/superMap',
    name: 'SuperMap服务',
  },
};

export const whiteListPaths = Object.keys(pathMap);

export const taskList = ref({
  listCount: 0,
  fileNameList: [],
});
