
import { defineComponent, getCurrentInstance, onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

export default defineComponent({
  name: 'BlankHeader',
  setup() {
    const clintWidth = ref(document.body.clientWidth);
    const layoutBool = ref(clintWidth.value > 1440);
    onMounted(() => {
      clintWidth.value = document.body.clientWidth;
      window.onresize = () => {
        clintWidth.value = document.body.clientWidth;
        // getElementsByClassName('layout-container')[0]
        layoutBool.value = clintWidth.value > 1440;
      };
    });
    const route = useRoute();
    const title = ref('' as any);
    const back = ref(false);
    const detailName = ref('' as any);
    const proxy = (getCurrentInstance() as any).proxy as any;
    const getBread = () => {
      title.value = route.meta.title || '';
      back.value = route.meta.showBackButton as boolean;
      detailName.value = route.query.detailName;
    };
    getBread();

    function jumpBack() {
      const { activeMenu, backRoute } = route.meta;
      if (backRoute !== undefined) {
        switch (typeof backRoute) {
          case 'string':
            return proxy.$router.push(backRoute);
          case 'number':
            return proxy.$router.go(backRoute);
          case 'function':
            return backRoute(proxy.$route, proxy.$router);
        }
      } else {
        proxy.$router.replace({
          path: activeMenu,
          query: {
            page: proxy.$route.query.pp,
          },
        });
      }
    }
    watch(
      () => proxy.$route,
      (route) => {
        if (route.path.startsWith('/redirect/')) {
          return false;
        }
        getBread();
      },
    );
    function logs(res: any) {
      console.log(res);
      return res;
    }
    return {
      clintWidth,
      layoutBool,
      title,
      jumpBack,
      detailName,
      back,
      logs,
    };
  },
});
