import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-27e79955"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "packaged-pagination" }
const _hoisted_2 = ["total"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_pagination = _resolveComponent("el-pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", {
      class: "packaged-pagination__total",
      total: $props.total
    }, "共 " + _toDisplayString($props.total) + " 条", 9, _hoisted_2),
    _createVNode(_component_el_pagination, _mergeProps({
      onSizeChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('size-change', $event))),
      onCurrentChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('current-change', $event))),
      "current-page": $props.page,
      "page-sizes": $props.pageSizes,
      "page-size": $props.pageSize,
      "pager-count": 5,
      total: $props.total,
      layout: $props.layout
    }, _ctx.$attrs), null, 16, ["current-page", "page-sizes", "page-size", "total", "layout"])
  ]))
}