
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'linkItem',
  functional: true,
  props: {
    icon: {
      type: String,
      default: () => '',
    },
    title: {
      type: String,
      default: () => '',
    },
    hover: {
      type: Boolean,
      default: () => false,
    },
  },
});
