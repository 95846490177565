<template>
  <el-tooltip effect="dark" :visible-arrow="false" :content="name" :placement="placement">
    <span class="service-short-name">{{ showName }}</span>
  </el-tooltip>
</template>
<script>
import { defineComponent, computed } from 'vue';
import { getServiceShowName } from './utils';
export default defineComponent({
  name: 'ServiceName',
  props: {
    name: {
      type: String,
      default: '',
    },
    placement: {
      type: String,
      default: 'right',
    },
  },
  setup(props) {
    const showName = computed(() => getServiceShowName(props.name));
    return {
      showName,
    };
  },
});
</script>

<style lang="scss">
.service-short-name {
  &:hover {
    cursor: pointer;
  }
}
</style>
