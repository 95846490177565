// 0 表示 api地址， // 1 表示mock地址
export default {
  GET_REPOSITORY_LIST_URL: ['/repository', 'mock/repository/list.json'],
  POST_PULL_REPOSITORY: ['/repository/pull', 'mock/repository/pull.json'],
  POST_SHARE_REPOSITORY: ['/repository/share', 'mock/repository/pull.json'],
  POST_DISTRIBUTE_REPOSITORY: ['/repository/distribute', 'mock/repository/pull.json'],
  GET_SERVICE_DEPEND: ['/services/dependency', 'mock/repository/depend.json'],
  GET_REPOSITORY_DETAIL_URL: ['/repository/_', 'mock/repository/detail.json'],
  GET_REPOSITORY_HISTORY: ['/repoManage/getServiceHistory', 'mock/repository/history.json'],
  GET_REPOSITORY_SNAPSHOT: ['/repository/snapshot', 'mock/repository/snapshot-detail.json'],
  GET_REPOSITORY_HISTORY_APP: ['/repository/history', 'mock/repository/history.json'],
  GET_REPOSITORY_SERVICE_VERSIONS: ['/repository/service-versions', 'mock/repository/service-versions.json'],
  GET_APP_CENTER_APP_LIST: ['/app/manage/list'],
  POST_APP_CENTER_SAVE_INNER_APP: ['/app/manage/add_in'],
  POST_APP_CENTER_SAVE_OUTER_APP: ['/app/manage/add_out'],
  DELETE_APP_CENTER_APP: ['/app/manage/delete/_'],
  GET_APP_CENTER_INNER_LIST: ['/app/manage/sameApp'],
};
