// 0 表示 api地址， // 1 表示mock地址
export default {
  GET_APPS: ['/apps', '/mock/app/app-list.json'],
  GET_APP_DETAIL: ['/apps/_', '/mock/app/app-detail.json'],
  UPDATE_APP: ['/apps/_', 'mock/app/app-update.json'],
  UPDATE_APPS_MAIN: ['/apps/main/_'],
  DELETE_APP: ['/apps/delete/_', 'mock/app/app-update.json'],
  DELETE_APPS_DELETE: ['/apps/delete/_'],
  CREATE_APP: ['/apps', 'mock/app/app-update.json'],
  VALIDATE_NAME: ['/apps/name/usable', 'mock/app/validate-name.json'],
  POST_INIT_APP: ['/apps/init'],
  POST_APPLY_APP: ['/apps/changes/apply'],
  POST_START_APP: ['/apps/start'],
  ENGINE_CREATE_APP: ['/app'],
  ENGINE_SERVICES_APP: ['/app/{appId}/service'],
  ENGINE_PACKAGE_APP: ['/app/{appId}/package'],
  POST_STOP_APP: ['/apps/stop'],
  UPDATE_APP_STATUS: ['/apps/status/sync'],
  POST_APPS_MAIN: ['/apps/main'],
  GET_APPS_MAIN: ['/apps/main'],
  GET_APP_DEPEND: ['/apps/dependency', 'mock/repository/depend.json'],
  GET_LATEST_APP_INFO: ['/snapshot/latestApp/{appId}'],
  PUBLISH_APP_VERSION: ['/snapshot/buildApp'],
  GET_APP_FOR_APPLY: ['/repoManage/getAppForApply'],
  GET_APP_CENTER_APP_LIST: ['/apps/app-center/apps'],
};
