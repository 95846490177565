// 0 表示 api地址， // 1 表示mock地址
export default {
  GET_CATEGORY_TREE: ['/spaceServiceCatalog/tree'],
  POST_CATEGORY_ADD: ['/spaceServiceCatalog/insert'],
  POST_CATEGORY_EDIT: ['/spaceServiceCatalog/update'],
  POST_CATEGORY_DELETE: ['/spaceServiceCatalog/delete/{id}'],
  POST_MOVE_CATALOG_ITEM: ['/spaceServiceCatalog/move'],
  GET_SPACE_SERVICE_MOVE: ['/spaceService/moveTo'],
  GET_SPACE_SERVICE_LIST: ['/spaceService/list'],
  GET_SPACE_SERVICE_DETAIL: ['/spaceService/detail/{id}'],
  POST_SPACE_SERVICE_DELETE: ['/spaceService/delete/{id}'],
  POST_MULTIPLE_SERVICE_DELETE: ['spaceService/deleteBatch'],
  POST_SPACE_SERVICE_REGISTER: ['/spaceService/register'],
  POST_TAG_OPERATE: ['/spaceServiceTagInfo/operate'],
  GET_TAG_WITH_COUNT: ['/spaceServiceTagRef/countTag'],
  POST_UPDATE_SPACE_DETAIL: ['/spaceService/update'],
  GET_SPACE_SERVICE_TYPES: ['/spaceService/serviceTypeList'],
  VALIDATE_URL: ['/spaceService/validateUrl'],
  GET_AREA_CODE: ['spaceArea/listByParent/{parentId}'],
  GET_LIST_WITH_PICTURE: ['/services/service-resource-center/list'],
  GET_TOKEN: ['/oneMap/validateOneMapToken'],
  GET_SESSION_BY_TOKEN: ['/users/oneMap/token2session'],
  GET_SPACE_EXCEL: ['/spaceService/exportList'],
  GET_AREA_NAME: ['/spaceServiceToken/getAreaInfo'],
  GET_MY_TOKENS: ['/spaceServiceToken/page'],
  POST_ADD_UPDATE_TOKEN: ['/spaceServiceToken/addOrUpdate'],
  POST_DELETE_TOKEN: ['/spaceServiceToken/batchDelete'],
  GET_ALL_SPACE_LIST: ['/spaceApproval/availableList'],
  GET_IDENTIFY_BUTTON: ['/spaceApproval/identifyButton'],
  POST_APPLY: ['/spaceApproval/apply'],
  POST_UPDATE_APPLY_DETAIL: ['/spaceApproval/updateApplyDetail'],
  POST_APPLY_LIST: ['/spaceApproval/applyList'],
  POST_UPDATE_APPLY_STATUS: ['/spaceApproval/updateStatus'],
  GET_APPLY_STATUS_COUNT: ['/spaceApproval/statusCount'],
  GET_ROOT_AREA_CODE: ['/spaceArea/rootArea'],
  GET_NEXT_LEVEL_AREA_CODE: ['/spaceArea/listByParent'],
};
