<template>
  <el-row class="sa-descriptions" :gutter="20">
    <slot />
  </el-row>
</template>
<script>
import { provide } from '@vue/runtime-core';
export default {
  name: 'SaDescriptions',
  props: {
    column: {
      type: Number,
      default: 3,
    },
  },
  setup(props) {
    provide('SA_DESCRIPTIONS', props);
  },
};
</script>
<style lang="scss" scoped>
.sa-descriptions {
  margin: 0;
  padding: 0;
  list-style: none;
}
</style>
