import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1d06c9c2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sa-tooltip" }
const _hoisted_2 = { class: "sa-tooltip__placeholder" }
const _hoisted_3 = { class: "sa-tooltip__content" }
const _hoisted_4 = { style: {"display":"inline-block","max-width":"500px"} }
const _hoisted_5 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tooltip = _resolveComponent("el-tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", { ref: "placeholder" }, _toDisplayString(_ctx.content), 513)
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.showTooltip)
        ? (_openBlock(), _createBlock(_component_el_tooltip, {
            key: 0,
            effect: "dark",
            "visible-arrow": _ctx.visibleArrow,
            placement: _ctx.placement
          }, {
            content: _withCtx(() => [
              _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.content), 1)
            ]),
            default: _withCtx(() => [
              _createElementVNode("span", null, _toDisplayString(_ctx.content), 1)
            ]),
            _: 1
          }, 8, ["visible-arrow", "placement"]))
        : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.content), 1))
    ])
  ]))
}