
import { defineComponent, onBeforeUnmount, reactive, ref } from 'vue';
// import breadCurmb from '@/components/bread-curmb/Index.vue';
import { userCurrentProject, userProjectList, userInfo } from '@/layout/messageCenter/user-info';
import { postCurrentProject, logout } from '@/api/auth';
import Message from 'element-plus/es/el-message';
import { useRoute, useRouter } from 'vue-router';
import { alloverEdit, currentids } from '@/layout/messageCenter/routerRef';
import { ElMessageBox } from 'element-plus';
import { taskList } from '@/components/global-iframe/dataCenter';

export default defineComponent({
  name: 'navBar',
  components: {
    // breadCurmb,
  },
  setup() {
    const projectList = reactive([
      {
        id: 1,
        name: '测试项目1',
      },
      {
        id: 2,
        name: '测试项目2',
      },
    ]);
    const route = useRoute();
    const handleDropClick = async (project: any) => {
      if (project.id !== userCurrentProject.value.id) {
        if (alloverEdit.value) {
          const res = await ElMessageBox.confirm('即将切换项目，正在编辑的内容将无法保存，是否切换?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'error',
          });
          console.log(res, 'this is awaitData');
        }
        alloverEdit.value = false;
        postCurrentProject({ id: project.id }).then(() => {
          userCurrentProject.value = project;
          localStorage.setItem('projectId', project.id);
          localStorage.setItem('projectName', project.name);
          const { meta, fullPath } = route;
          if (meta.checkPath) {
            if (meta.checkPath === 'current') {
              window.location.href = fullPath;
            } else {
              window.location.href = (meta.activeMenu as string) || '/';
            }
          } else {
            window.location.reload();
          }
        });
      }
    };
    const dialogVisible = ref(false);

    const router = useRouter();
    const jump2UserCenter = () => {
      router.push('/user-management');
    };

    const toAboutInfo = () => {
      dialogVisible.value = true;
    };

    const handleClose = () => {
      dialogVisible.value = false;
    };

    let intervalLogout = null as any;

    const handleLogout = () => {
      logout().then((res: any) => {
        const urls = res.data.logoutUrl;
        if (urls) {
          localStorage.removeItem('authHash');
          window.location.href = '/login';
        } else {
          Message.error('登出失败');
        }
      });
    };
    onBeforeUnmount(() => {
      if (intervalLogout) {
        clearInterval(intervalLogout);
        intervalLogout = null;
      }
    });
    return {
      projectList,
      userCurrentProject,
      userProjectList,
      handleDropClick,
      userInfo,
      handleLogout,
      jump2UserCenter,
      toAboutInfo,
      dialogVisible,
      handleClose,
      currentids,
      taskList,
    };
  },
});
