<template>
  <el-select
    v-model="modelValue"
    multiple
    filterable
    remote
    reserve-keyword
    :multiple-limit="limit"
    :placeholder="placeholder"
    :remote-method="remoteMethod"
    :loading="loading"
    @change="handleChange"
    v-bind="$attrs"
    clearable
    style="width: 100%"
  >
    <el-option v-for="item in userList" :key="item.id" :label="item.displayName" :value="item.id"> </el-option>
  </el-select>
</template>
<script>
import { ref, watch } from 'vue';
import { fetchUserList } from '../owners-select/owners-util';
import { userInfo } from '@/layout/messageCenter/user-info';
export default {
  name: 'SaUserSelector',
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    limit: {
      type: Number,
      default: 10,
    },
    placeholder: {
      type: String,
      default: '请输入关键词',
    },
    filterByProject: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['change'],
  setup(props, { emit }) {
    const modelValue = ref([]);
    const loading = ref(false);
    const userList = ref([]);
    const updateModelValue = () => {
      modelValue.value = props.value.map((u) => u?.id || u);
    };

    watch(
      () => [props.value],
      () => updateModelValue(),
    );
    updateModelValue();

    const fetchUsers = async (keyword = '') => {
      try {
        const users = await fetchUserList(keyword || ' ', props.filterByProject);
        const loginUser = users.find((item) => Number(item.id) === Number(userInfo.value.userId));
        if (!loginUser) {
          users.push({
            id: userInfo.value.userId,
            displayName: userInfo.value.displayName,
            userName: userInfo.value.userName,
          });
        }
        userList.value = users;
      } catch (e) {
        console.log(e);
      }
    };

    fetchUsers();

    const remoteMethod = async (keyword) => {
      loading.value = true;
      await fetchUsers(keyword);
      loading.value = false;
    };

    const handleChange = (value) => {
      emit(
        'change',
        value,
        userList.value.filter((user) => value.includes(user.id)),
      );
    };

    return {
      modelValue,
      loading,
      userList,
      remoteMethod,
      handleChange,
    };
  },
};
</script>
