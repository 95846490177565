// 0 表示 api地址， // 1 表示mock地址
export default {
  GET_SERVICE_LIST: ['/services', '/mock/services/list.json'],
  GET_ALL_SERVICE_LIST: ['/services/all', '/mock/services/list.json'],
  GET_ALL_SERVICEMODEL_LIST: ['/services/alllsit', '/mock/services/list.json'],
  ADD_SERVICE: ['/services', '/mock/services/create.json'],
  UPDATE_SERVICE: ['/services/_', '/mock/services/update.json'],
  GET_SERVICE_BY_ID: ['/services/_', '/mock/services/service-detail.json'],
  GET_SERVICE_INFO_BY_ID: ['/services/info/_', '/mock/services/service-info.json'],
  BUILD_SERVICE: ['/services/build', '/mock/services/service-build.json'],
  BUILD_SERVICE_NEW: ['/snapshot/build'],
  INIT_SERVICE: ['/services/init/_', '/mock/services/service-init.json'],
  DELETE_SERVICE: ['/services/delete'],
  GET_SERVICE_APIS: ['/services/apis', '/mock/services/api-list.json'],
  UPDATE_SERVICE_APIS: ['/services/_/apis', '/mock/services/api-update.json'],
  GET_LOG_RUNTIME: ['/logs/runtime'],
  GET_LOG_LIST: ['/logs'],
  GET_CHANGES: ['/services/_/changes'],
  POST_CHANGES_APPLY: ['/services/changes/apply'],
  START_SERVICE: ['/services/start'],
  STOP_SERVICE: ['/services/stop'],
  SERVICE_NAME_TEST: ['/services/name/usable'],
  GET_SERVICE_TRACE_ID: ['/logs/runtime/traceid', '/mock/services/service-trace-id.json'],
  UPDATE_SERVICE_STATUS: ['/services/sync/status', '/mock/services/update-service-status.json'],
  GET_SERVICE_CONFIG: ['/services/_/config', '/mock/services/get-service-config.json'],
  GET_SERVICE_UPGRADE_SCRIPT: ['/services/_/upgrade', '/mock/services/get-service-upgrade.json'],
  POST_SERVICE_RELEASE: ['/services/release', '/mock/services/release.json'],
  GET_SERVICE_DEPENDENCY_LIST: ['/services/dependencies', '/mock/services/dependencies.json'],
  GET_RELEASE_CHECK: ['/services/release/check', '/mock/services/check.json'],
  GET_DEPENDENCY_LIST: ['/services/dependency/list'],
  UPDATE_DEPENDENCY: ['/services/dependency/update'],
  DELETE_DEPENDENCY: ['/services/dependency/delete'],
  ADD_DEPENDENCY: ['/services/dependency/add'],

  GET_SERVICE_API_LIST: ['/api/list'],
  GET_SERVICE_API_INFO: ['/api/select'],
  SAVE_SERVICE_API: ['/api/save'],
  DELETE_SERVICE_API: ['/api/delete'],
  UPDATE_SERVICE_API: ['/api/update'],
  GET_DTO_MODEL: ['/dto/select'],
  GET_DTO_MODEL_ALL_LIST: ['/dto/list'],
  ADD_DTO_MODEL: ['/dto/add'],
  UPDATE_DTO_MODEL: ['/dto/update'],
  REMOVE_DTO_MODEL: ['/dto/delete'],
  GET_DTO_MODEL_LIST: ['/mock/service/dto-list.json', '/mock/service/dto-list.json'],

  GET_SERVICE_API_PARAMS: ['/api/param/select'],
  SAVE_SERVICE_API_PARAMS: ['/api/param/update'],
  GET_START_CHECK: ['/services/start/check', '/mock/services/check.json'],

  GET_LAST_VERSION: ['/snapshot/latest'],

  GET_DOMAIN_CONFIG: ['/services/domain-config', '/mock/services/domainConfig.json'],
  GET_PRE_DATA_START: ['/prepub/start', '/mock/services/prepub-start.json'],
  GET_MODEL_NAMES: ['/prepub/modelNames', '/mock/services/model-names.json'],
  GET_DATA_CHANGES: ['/prepub/dataChanges', '/mock/services/data-changes.json'],
  GET_DATA_CHANGES_NEW: ['/prepub/dataChangesNew', '/mock/services/data-changes.json'],
  DATA_SELECT: ['/prepub/dataSelect'],
  DATA_DE_SELECT: ['/prepub/dataDeselect'],
  RELEASE_CANCEL: ['/prepub/cancel'],
  GET_APPS_MAINS_TREE: ['/apps/main/menus/tree'],
  GET_APPS_MAINS: ['/apps/micro'],
  POST_APPS_MAINS: ['/apps/main/menus'],
  POST_MAIN_MENUS: ['/apps/main/menus/_'],
  POST_APPS_DELETE: ['/apps/main/delete/menus/_'],
  GET_SERVICES_APIS: ['/apps/services/apis'],
  GET_APPID_PERMISSION: ['/apps/_/permissions'],
  POST_APPID_PERMISSION: ['/apps/_/permission'],
  POST_APPS_PERMISSION: ['/apps/permission/_'],
  POST_DELETE_PERMISSION: ['/apps/permission/delete/_'],

  GET_CANUSE_EXTERNAL: ['/models/external/usable', '/mock/services/model-canuse-external.json'],
  GET_EXTERNAL_LIST: ['/models/external/list', '/mock/services/model-select-external.json'],
  POST_IMPORT_EXTERNAL: ['/models/external/import', '/mock/services/model-import-external.json'],
  POST_DELETE_EXTERNAL: ['/models/external/delete', '/mock/services/model-delete-external.json'],
};
