export const isProduction = true || process.env.NODE_ENV === 'production';

const useMock = Number(process.env.VUE_APP_USE_MOCK) === 1;
export const getUrl = (urls: string[], params = '_'): string => {
  const [api, mock] = urls;
  if (mock && useMock) {
    return mock.replace('_', params);
  }
  return api.replace('_', params);
};
const URL_PARAMS_REG = /{(\w+)}/gim;

/**
 * 将URL中的变量替换
 * 与上面方法不同的事，该方法支持多个变量，变量名为 {var}
 * @param url
 * @param params
 * @returns
 */
export const parseApiUrl = (url: string | string[], params: any) => {
  if (!Array.isArray(url)) {
    // eslint-disable-next-line no-param-reassign
    url = [url];
  }
  return url.map((u) => u.replace(URL_PARAMS_REG, (a: any, b: string) => params[b]));
};
