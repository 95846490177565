// 0 表示 api地址， // 1 表示mock地址
export default {
  GET_MODEL_LIST: ['/models', '/mock/schema/model-list.json'],
  GET_MODEL_LIST_ALL: ['/models/all', '/mock/schema/model-list-all.json'],
  GET_SERVICE_MODEL_LIST: ['/services/models', '/mock/schema/service-model-list.json'],
  GET_MODEL_DETAIL: ['/models', '/mock/schema/model-detail.json'],
  CREATE_MODEL: ['/models', '/mock/schema/model-create.json'],
  IMPORT_MODEL: ['/models/import', '/mock/schema/model-create.json'],
  UPDATE_MODEL: ['/models', '/mock/schema/model-update.json'],
  GET_CTRL_DIM: ['/models/ctrdim'],
  GET_CTRL_DIM_BYFIELD: ['/models/ctrdim/field'],
  GET_CTRL_DIM_ALL: ['/models/ctrdim/all'],
  CREATE_CTRL_DIM: ['/models/add/ctrdim'],
  UPDATE_CTRL_DIM: ['/models/ctrdim/update', '/mock/schema/model-update.json'],
  DELETE_MODEL: ['/models/delete', 'mock/schema/model-delete.json'],
  DELETE_CTRL_DIM: ['/models/ctrdim/delete', 'mock/schema/model-delete.json'],
  CREATE_RELATION: ['/models/relation', 'mock/schema/model-create.json'],
  UPDATE_RELATION: ['/models/relation/_', 'mock/schema/model-create.json'],
  REMOVE_RELATION: ['/models/relation/delete', 'mock/schema/model-create.json'],
  UPDATE_CONFIG: ['/services/config', 'mock/schema/model-create.json'],
  UPDATE_FIELDS: ['/models/_/fields', 'mock/schema/model-create.json'],
  PARSE_ENTITY: ['/parser/java'],
  GET_STANDARD_FIELDS: ['/models/standard-fields'],
  UPDATE_INDEX: ['/models/_/index', 'mock/schema/model-create.json'],
  GET_INDEX_FIELDS: ['/models/_/indexEnableFields', 'mock/schema/model-create.json'],
  GET_CUSTOM_TYPE: ['modelInfo/getAllDbType'],
};
